import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

type IconWineProps = {
  size?: string | number,
  width?: string | number,
  height?: string | number,
}

const IconWine: React.FC<IconWineProps> = props => {

  const { imageFile } = useStaticQuery(graphql`
    {
      imageFile: file(absolutePath: {regex: "/images/logo/"}, name: {eq: "icon-wine"}) {
        id
        name
        ext
        childImageSharp {
          id
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  const p = props;

  return <GatsbyImage
    className=""
    imgClassName=""
    image={imageFile.childImageSharp.gatsbyImageData}
    style={{
      width: p.width ?? p.size,
      height: p.height ?? p.size,
    }}
    alt={imageFile.name}
    objectFit="contain"
  />
}

export default IconWine;