import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { GatsbyFile } from '../../types/gatsby.types';
import BaseImage from '../BaseImage/BaseImage';
import BaseLink from '../BaseLink/BaseLink';
import PageSection from '../PageSection/PageSection';
import IconSeafood from '../Symbols/symbolDefs/IconSeafood.image';
import IconWater from '../Symbols/symbolDefs/IconWater.image';
import IconWilderness from '../Symbols/symbolDefs/IconWilderness.image';
import IconWine from '../Symbols/symbolDefs/IconWine.image';
import './SectionCategoryNavigation.scss';

const primaryCategory = [
  {
    Label: "Wine",
    href: "/explore/#wine",
    filename: "bg-wine.jpg",
    Icon: IconWine
  },
  {
    Label: "Water",
    href: "/explore/#water",
    filename: "bg-water.jpg",
    Icon: IconWater,
  },
  {
    Label: "Wilderness",
    href: "/explore/#wilderness",
    filename: "bg-wilderness.jpg",
    Icon: IconWilderness,
  },
  {
    Label: "Cuisine",
    href: "/explore/#cuisine",
    filename: "bg-cuisine.jpg",
    Icon: IconSeafood,
  },
]
const secondaryCategory = [
  {
    Label: "Heritage",
    href: "/explore/#heritage",
    filename: "bg-vintage.jpg",
  },
  {
    Label: "Shopping",
    href: "/explore/#shopping",
    filename: "bg-shopping.jpg",
  },
  {
    Label: "Activities",
    href: "/explore/#activities",
    filename: "bg-activities.jpg",
  },
  {
    Label: "Accommodation",
    href: "/explore/#accommodation",
    filename: "bg-accom.jpg",
  }
]

type SectionCategoryNavigationProps = {}

const SectionCategoryNavigation: React.FC<SectionCategoryNavigationProps> = props => {

  const { bgCategoryImages } = useStaticQuery(graphql`
    {
      bgCategoryImages: allFile(
        filter: {absolutePath: {regex: "/images/background/etm-categories/"}}
      ) {
        nodes {
          id
          name
          ext
          childImageSharp {
            id
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  `);

  const s = {
    get filenameToGatsbyImageData() {
      const gatsbyImages: GatsbyFile[] = bgCategoryImages.nodes;
      return gatsbyImages.reduce((obj, item) => ({ ...obj, [`${item.name}${item.ext}`]: item.childImageSharp.gatsbyImageData }), {}) as { [k in string]: IGatsbyImageData };
    },
  }

  return <PageSection id="SectionCategoryNavigation" className="SectionCategoryNavigation Row" observeVisibility>
    <div className="PrimaryCategory">
      {primaryCategory.map((c, idx) => <div className="Category" key={idx}>
        <div className="CategoryBox">
          <BaseLink href={c.href}>
            <BaseImage
              className="CategoryBoxBackground"
              image={s.filenameToGatsbyImageData[c.filename]}
              altText={c.Label}
              imageType="gatsbyDynamic"
            />
            <div className="CategoryBoxContent">
              <c.Icon size={80} />
              {c.Label}
            </div>
          </BaseLink>
        </div>
      </div>)}
    </div>

    <div className="SecondaryCategory">
      {secondaryCategory.map((c, idx) => <div className="Category" key={idx}>
        <div className="CategoryBox">
          <BaseLink href={c.href}>
            <BaseImage
              className="CategoryBoxBackground"
              image={s.filenameToGatsbyImageData[c.filename]}
              altText={c.Label}
              imageType="gatsbyDynamic"
            />
            <div className="CategoryBoxContent">
              {c.Label}
            </div>
          </BaseLink>
        </div>
      </div>)}
    </div>
  </PageSection>
}

export default SectionCategoryNavigation;